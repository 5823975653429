import React, { useEffect, useState } from "react";
import { getPosts } from "../contentful";
import BlogItem from "./BlogItem";

const Posts = () => {
  const [posts, setPosts] = useState([]);

  const lang = window.localStorage.lang;

  useEffect(() => {
    getPosts(lang).then((data) => setPosts(data));
  }, [lang]);

  return (
    <div className="content">
      {posts.map((post, index) => (
        <BlogItem key={index} article={post} />
      ))}
    </div>
  );
};

export default Posts;
