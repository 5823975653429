import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { getBiog } from "../contentful";
import marked from "marked";


const Biog = () => {
  const [biog, setBiog] = useState([]);

  useEffect(() => {
    let switchedLang = window.localStorage.lang;
    if(!window.localStorage.lang){switchedLang = 'en-US'}

    getBiog(switchedLang).then((data) => setBiog(data));
  }, []);

  return (
    <div >

      {biog.map((biog) => {
        const block1 = marked(biog.fields.biography);

        return (
          <div  >
           <div >
            <Row>
              <Col md={9}>
                <div style={{padding:25}}
                  dangerouslySetInnerHTML={{ __html: block1 }}
                />
                   <div className="biog-logos">
                <img alt="" src={biog.fields.logo.fields.file.url} />
                <img alt="" src={biog.fields.logo2.fields.file.url} />
                </div>
              </Col>
              <Col md={3}>
                <div className="text-center">
                <img
                  style={{ width: "100%", marginBottom: "15px" }}
                  src={biog.fields.image.fields.file.url}
                  alt=""
                />
             
                </div>
              </Col>
            </Row>
            </div>
           { biog.fields.image2 && <img src={biog.fields.image2.fields.file.url} style={{width:'100%'}} />}
            
          </div>
        );
      })}
   
    </div>
  );
};

export default Biog;
