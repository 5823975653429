export default {
    translations: {
      Home:"Home",
      Tag:'Személyközpontú coaching',
      HomeTag1: 'a változásért',
      HomeTag2:'önismeret ~ karrier ~ kapcsolatok',
      ContactText: 'Elérhetőségeim:',
      ContactFormName: 'Your name:',
      ContactFormEmail: 'Your email:',
      ContactFormMessage: 'Your message',
      ContactFormButton: 'send',

      Contact:"Kapcsolat",
      AboutMe: "Bemutatkozom",
      WhatIDo: 'Coaching',
      Blog: 'Blog',
      Testimonials: 'Vélemények',
     More: 'részletek',
     ContactButton: 'Ingyenes bemutatkozó konzultáció foglalása itt.',

      ReadMore: 'tovább',
    share: 'Megosztás',
    GoToBlog: 'vissza a blog oldalra',
    FindOutMore:'további részletek'


            }
          
    
}