const client = require('contentful').createClient({
    space: '5w1pe98t0epx',
    accessToken: 'RDroB0ftiXQLbakjhsWM2EHyT0-QewA59Vtk3exjXwk'
  })
  var gt = new Date().toISOString();

  
  
  
  
  
  
  const getBiog = (lang) => client.getEntries({content_type: 'aboutMe', locale: lang}).then(response => response.items)
  const getWhatIDoPage = async (lang) => client.getEntries({content_type: 'whatIDo', locale: lang}).then(response => response.items)
  const getWhatIDoItems = (lang) => client.getEntries({content_type: 'whatIDoItem', locale: lang, order: 'fields.order'}).then(response => response.items)
  const getWhatIDoItem = (slug, lang) => client.getEntries({content_type: 'whatIDoItem', locale: lang, 'sys.id': slug }).then(response => response.items)
  
  const getBlogPage = ( lang) => client.getEntries({ content_type: 'blogPage', locale: lang}).then(response => response.items)
  const getPosts = ( lang) => client.getEntries({ content_type: 'blogPost', locale: lang}).then(response => response.items)
  const getSinglePost = (slug, lang) => client.getEntries({'sys.id': slug, content_type: 'blogPost', locale: lang}).then(response => response.items)
  
  const getTestimonials = () => client.getEntries({ content_type: 'testimonial', order: 'fields.order'}).then(response => response.items)
  const getTestimonialsPage = (lang) => client.getEntries({ content_type: 'testimonialsPage', locale: lang}).then(response => response.items)

  
  



  




  export {getTestimonialsPage, getTestimonials, getBlogPage, getWhatIDoItem, getWhatIDoItems, getWhatIDoPage, getSinglePost, getPosts, getBiog}