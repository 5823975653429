import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getWhatIDoItem } from "../contentful";
import marked from "marked";
import { Row, Col } from "react-bootstrap";


const WhatIDoItem = () => {
  const { id } = useParams();
  const lang = window.localStorage.lang;

  const [content, setContent] = useState([]);
  const quote = {
    padding: 30,
    textAlign: "center",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getWhatIDoItem(id, lang).then((data) => setContent(data));
  }, []);
  return (
    <div className="content">
      {content.map((data) => {
        let text = marked(data.fields.text);
        let desc;
        if (data.fields.description) {
          desc = marked(data.fields.description);
        }
        return (
          <div>
            <Row>
              <Col>
                <div className="title-box">
                  <h1>{data.fields.title}</h1>
                  {data.fields.description && (
                    <section
                      style={quote}
                      dangerouslySetInnerHTML={{ __html: desc }}
                    />
                  )}
                </div>
              </Col>
              <Col>
                <img
                  src={data.fields.mainImage.fields.file.url}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <div
              style={{ marginTop: 25 }}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
        );
      })}
 
    </div>
  );
};

export default WhatIDoItem;
