export default {
    translations: {
        
            Home:"Home",
            Tag: 'Welcome to person-centred coaching',
            HomeTag1: 'It\'s all about you',
            HomeTag2:'your dreams ~ your goals ~ your life',
            Contact:"Contact",
            ContactText: 'Feel free to contact me. Book a free discovery call by phone, or email using the form below.',
            ContactFormName: 'Your name:',
            ContactFormEmail: 'Your email:',
            ContactFormMessage: 'Your message',
            ContactFormButton: 'send',
            AboutMe: "About Me",
            WhatIDo: 'What I do',
            Blog: 'Blog',
            Testimonials: 'Testimonials',
            More: 'more',
            ContactButton: 'Book a free discovery call to explore how coaching can help you.',
           
            ReadMore: 'read more',
            share: 'Share this post on your social media:',
            GoToBlog: 'go to blog',
            FindOutMore:'find out more'

    
       
        
        
        
        
    }
}