import React from 'react'


const ContactButton = (props) => {
    return (
        <div className="text-center" style={{padding: 30, marginTop:40}}>
                        <a className="btn btn-light" href={props.link}>{props.text}</a>

        </div>
    )
}

export default ContactButton
