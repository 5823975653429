import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getSinglePost } from "../contentful";
import marked from "marked";
import { Row, Col } from "react-bootstrap";
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  FacebookShareButton,
} from "react-share";
import Translator from "./i18n/Translator";

const SinglePost = () => {
  const [post, setPost] = useState([]);

  const { id } = useParams();
  let lang = window.localStorage.lang;
  useEffect(() => {
    getSinglePost(id, lang).then((data) => setPost(data));
  }, [id, lang]);

  return (
    <div>
      {post.map((post) => {
        let text = marked(post.fields.text);
        let date = new Date(post.fields.date)
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
let lang = 'en-US'
if(window.localStorage.lang){lang = window.localStorage.lang}
        return (
          <div className="content" bg-light>
            <Row>
              <Col>
                <h3>{post.fields.title}</h3>
        {post.fields.date && <small>{date.toLocaleDateString(lang, options)}</small>}
        <hr/>
                <p>{post.fields.description}</p>
              </Col>
              <Col>
                <img
                  src={post.fields.mainImage.fields.file.url}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <div
              style={{ marginTop: 25 }}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
        );
      })}
      <div style={{ padding: 20 }}>
        <div>
          <small>
            {" "}
            <Translator path="share" />
          </small>
          <br />
          <TwitterShareButton
            url={window.location}
            children={<TwitterIcon size={24} />}
          />
          <LinkedinShareButton
            url={window.location}
            children={<LinkedinIcon size={24} />}
          />
          <FacebookShareButton
            url={window.location}
            children={<FacebookIcon size={24} />}
          />
        </div>
        <Link style={{ marginTop: 25 }} className="btn btn-light" to="/blog">
          <Translator path="GoToBlog"/>
        </Link>
      </div>

    </div>
  );
};

export default SinglePost;
