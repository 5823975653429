import React from 'react'


const Footer = (props) => {
return <div className="footer bg-light">
    <small>Designed and built by Adam Woolf @ <a href='https://www.webspinner.eu'>WebSpinner.eu</a></small>
   
    
</div>

}

export default Footer