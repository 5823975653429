import React from "react";
import marked from "marked";
import { Link } from "react-router-dom";
import Translator from './i18n/Translator'
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  FacebookShareButton,
} from "react-share";
import { Row, Col } from "react-bootstrap";

const BlogItem = ({ article }) => {
  console.log(article);
  const { title, mainImage, text,  description, date } = article.fields;
  const {id} = article.sys
  const postBody = marked(text);
  const excerpt = postBody.split(" ").splice(0, 20).join(" ") + "...";
  const postDate = new Date(date)
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
let lang = 'en-US'
if(window.localStorage.lang){lang = window.localStorage.lang}

  return (
    <div className="post-card" >
            <h4>{title}</h4>
            {date && <small>{postDate.toLocaleDateString(lang, options)}</small>}


      <Row>
        <Col xs={4}>
      {mainImage && <img style={{maxWidth:'100%', maxHeight:'250px'}} src={mainImage.fields.file.url} />}
        </Col>
        <Col xs={8}>
        <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', height:'100%'}}>
        {description && 
       <div dangerouslySetInnerHTML={{__html: marked(description)}} /> 
       }
        <Link to={"/blog/" + id}><Translator path="ReadMore"/>
        </Link>
        </div>
</Col>
      </Row>
      
      {/* <section dangerouslySetInnerHTML={{ __html: excerpt }} /> */}
      <div></div>
      <div className="post-list-buttons">
        
       
      </div>

      <hr />
    </div>
  );
};

export default BlogItem;
