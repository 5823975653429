import React from 'react'
import { useTranslation } from 'react-i18next'
// useTranslation is a hook 
// that returns a translation function (t) and the i18n instance

// importing the flags (images and component)
import HU from '../../assets/hu.png'
import UK from '../../assets/UK.png'



import Flag from './Flag'

const I18n = () => {
  const { i18n } = useTranslation()
  // i18n instance

  function handleChangeLanguage(language) {
    // changing the language when this function is called
    i18n.changeLanguage(language)
    window.localStorage.setItem('lang', language)
    window.location.reload()
  }

  const selectedLanguage = i18n.language
  return (
    <div className="flags-container">
      <Flag
      className="country-flag"
        image={HU}
        isSelected={selectedLanguage === 'hu-HU'} // check if pt-BR is selected
        onClick={() => handleChangeLanguage('hu-HU')} // changes the language to pt-BR
      />
      <Flag
      className="country-flag"
        image={UK}
        isSelected={selectedLanguage === 'en-US'} // check if en-US is selected
        onClick={() => handleChangeLanguage('en-US')} // changes the language to en-US
      />
    </div>
  )
}

export default I18n