import React, { useState, useEffect } from "react";
import "../styles/stylesheet.css";

import { Row, Col } from "react-bootstrap";

import Translator from "./i18n/Translator";
import blue from "../assets/imgs/blue.png";

import { getWhatIDoItems } from "../contentful";
import WhatIDoCard from "./WhatIDoCard";

const Main = () => {
  const [items, setItem] = useState([]);
  let lang = "en-US";
  if (window.localStorage.lang) {
    lang = window.localStorage.lang;
  }
  useEffect(() => {
    getWhatIDoItems(lang).then((data) => setItem(data));
    window.scrollTo(0, 0);
  }, [lang]);
  return (
    <div style={{ padding: 20 }}>
      <div>
        <div className="home-banner">
          <h1 style={{textAlign:'center'}} >
            <Translator path="Tag" />
          </h1>

          <h4 style={{ marginTop: 25 }}>
            <Translator path="HomeTag1" />
          </h4>
          <div>
            <img
              style={{ width: 130, margin: "-13px 0 15px" }}
              src={blue}
              alt="blue"
            />
          </div>
          {/* <h5><Translator path="HomeTag2"/></h5> */}
        </div>
        <Row>
          {items.map((item) => {
            return (
              <Col key={item.fields.title} md={4}>
                <WhatIDoCard item={item} />
              </Col>
            );
          })}
        </Row>
      </div>
    
    </div>
  );
};

export default Main;
