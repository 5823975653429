import React, { useEffect, useState } from "react";
import { getTestimonials, getTestimonialsPage } from "../contentful";
import { Row, Col } from "react-bootstrap";
import marked from "marked";

const Testimonials = () => {
  const [tests, setTests] = useState([]);
  const [page, setPage] = useState([]);

  let lang = window.localStorage.lang;
  useEffect(() => {
    getTestimonialsPage(lang).then((data) => setPage(data));
    getTestimonials().then((data) => setTests(data));
  }, [lang]);
  return (
    <div className="content">
      {page.map((a) => {
        let text;
        if (a.fields.text) {
          text = marked(a.fields.text);
        }

        return (
          <Row>
            <Col>
              <div className="title-box">
                <h3>{a.fields.title}</h3>
                {a.fields.text && (
                  <section dangerouslySetInnerHTML={{ __html: text }} />
                )}
              </div>
            </Col>
            <Col>
              <img
                src={a.fields.mainImage.fields.file.url}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
        );
      })}
      {tests.map((test) => {
        return (
          <div className="text-box">
            <h5>{test.fields.title}</h5>
            <hr />
            <p>
              <em>{test.fields.mainText}</em>
              <br />
              <small>{test.fields.name}</small>
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Testimonials;
