import React, { Component } from 'react';
import Main from './components/Main';
import Contact from './components/Contact';
import Posts from './components/Posts'
import Footer from './components/Footer'
import Toolbar from './components/Toolbar/Toolbar'
import SideDrawer from './components/SideDrawer/SideDrawer'
import Backdrop from './components/Backdrop/Backdrop'
import SinglePost from './components/SinglePost'
import Biog from './components/Biog'
import {getWhatIDoItems} from './contentful';

import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Nav from './components/NavBar'
import WhatIDoPage from './components/WhatIDoPage';
import WhatIDoItem from './components/WhatIDoItem';
import Testimonials from './components/Testimonials';
import ContactButton from './components/ContactButton';
import Translator from './components/i18n/Translator';

class App extends Component {
  constructor(props){
    super(props);

    this.state ={
      sideDrawerOpen: false,
      whatIDoItems: [],
    };
  }


  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  };

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false})
  }

  componentDidMount(){
getWhatIDoItems(window.localStorage.lang).then(data => this.setState({whatIDoItems: data}))
  }


  render(){

    let backdrop;

    if(this.state.sideDrawerOpen){
      backdrop = <Backdrop click={this.backdropClickHandler}/>
    }
  return (
    <Router>
  <div style={{height: '100%'}}>
  <Nav items={this.state.whatIDoItems} />

      {/* <Toolbar items={this.state.whatIDoItems}   drawerClickHandler={this.drawerToggleClickHandler} />
      <SideDrawer items={this.state.whatIDoItems}  show={this.state.sideDrawerOpen} click={this.drawerToggleClickHandler}/>
      {backdrop}  */}

        <main style={{marginTop:'56px'}}></main>    
      <Switch>
    <Route exact path="/" component={Main}/>
    <Route path="/contact" component={Contact }/>
    <Route path="/blog/:id" component={SinglePost}/>
    <Route path="/blog" component={Posts}/>
    <Route path="/biography" component={Biog}/>
    <Route path="/whatIdo/:id" component={WhatIDoItem}/>
    <Route path="/whatIdo" component={WhatIDoPage}/>
    <Route path="/testimonials" component={Testimonials}/>

    </Switch>

    {window.location.pathname != '/contact' && <ContactButton 
      link={window.location.pathname === '/' ? "/whatIdo" : '/contact'}
    text={window.location.pathname === '/' ? <Translator path="FindOutMore"/> : <Translator path="ContactButton"/>}/>
  }
    <Footer/>

    </div>
    </Router>
  );
}
}

export default App;
