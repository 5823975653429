import React, { useState, useEffect } from "react";
import { getWhatIDoItems, getWhatIDoPage } from "../contentful";
import { Row, Col } from "react-bootstrap";
import marked from "marked";
import Translator from "./i18n/Translator";
import WhatIDoCard from "./WhatIDoCard";

const WhatIDoPage = () => {
  const [pageContent, setPageContent] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let chosenLang = 'en-US'
    if (window.localStorage.lang){chosenLang = window.localStorage.lang}
    getWhatIDoPage(chosenLang).then((data) => setPageContent(data));
    getWhatIDoItems(chosenLang).then((data) => setItems(data));
  }, []);
  return (
    <div className="content">
      {pageContent.map((item) => {
        let text = marked(item.fields.text);
        const image = {
          height: 300,
          backgroundImage: `url(${item.fields.mainImage.fields.file.url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        };
        return (
          <div key={item.fields.title}>
            <Row>
              <Col md={6}>
                <div className="title-box">
                  <h3>
                    <Translator path="WhatIDo" />
                  </h3>
                </div>
              </Col>
              <Col md={6}>
                <div style={image}></div>
                {/* <img
                  src={item.fields.mainImage.fields.file.url}
                  style={{ width: "100%" }}
                /> */}
              </Col>
            </Row>
            <div
              style={{ marginTop: 10 }}
              dangerouslySetInnerHTML={{ __html: text }}
            />
            <Row>
              {items.map((item) => {
                return (
                  <Col xs={4}>
                    <WhatIDoCard item={item} />
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      })}
   
    </div>
  );
};

export default WhatIDoPage;
