import React from 'react'
import { Link } from 'react-router-dom'
import Translator from './i18n/Translator'



const WhatIDoCard = ({item}) => {
  const imageDiv = {
    backgroundImage: `url(${item.fields.mainImage.fields.file.url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    height: 300,
    width: '100%'
  }
    return (
       <div>
        <div style={{marginBottom: 10}}
         className="main-box"
        >
          <Link to={`/whatIdo/${item.sys.id}`}>
            <div>
              <div style={imageDiv} >
            {/* <img
              src={item.fields.mainImage.fields.file.url}
              style={{ width: "100%" }}
            /> */}
            </div>
            </div>
            <div className="overlay">
              <h4><Translator path="More"/></h4>
            </div>
          </Link>
        
          </div>
          <h5 style={{marginTop:15}} className="text-center" >{item.fields.title}</h5>

      </div>
    )
}

export default WhatIDoCard
