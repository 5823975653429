import React from "react";
import { NavLink } from "react-router-dom";
import * as ReactBootStrap from "react-bootstrap";
import logo from "../assets/imgs/kingalogo.png";
import blue from "../assets/imgs/blue.png";
import I18n from "./i18n/i18n";
import Translator from "./i18n/Translator";

const Nav = ({ items }) => {
  return (
    <ReactBootStrap.Navbar
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="light"
    >
      <ReactBootStrap.Navbar.Brand href="/">
        <img alt="" src={logo} style={{ width: 200 }} />
      </ReactBootStrap.Navbar.Brand>
      <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
        <ReactBootStrap.Nav className="mr-auto">


          <ReactBootStrap.Nav.Link  href="/biography" >
            <Translator path="AboutMe" />
            <br />
            <img alt="" src={blue} />
          </ReactBootStrap.Nav.Link>

          <ReactBootStrap.NavDropdown
            as={NavLink}
            title={<Translator path="WhatIDo" />}
            id="collasible-nav-dropdown"
          >
            <ReactBootStrap.NavDropdown.Item
              className="nav-link"
              href="/whatIDo"
            >
              <Translator path="WhatIDo" />
              <br />
              <img alt="" src={blue} />
            </ReactBootStrap.NavDropdown.Item>

            {items.map((item) => (
              <ReactBootStrap.NavDropdown.Item
                className="nav-link"
                href={`/whatIDo/${item.sys.id}`}
              >
                {item.fields.title}
                <br />
                <img alt="" src={blue} />
              </ReactBootStrap.NavDropdown.Item>
            ))}
          </ReactBootStrap.NavDropdown>

          <ReactBootStrap.Nav.Link href="/blog" className="nav-link">
            <Translator path="Blog" />
            <br />
            <img alt="" src={blue} />
          </ReactBootStrap.Nav.Link>
          <ReactBootStrap.Nav.Link href="/contact" className="nav-link">
            <Translator path="Contact" />
            <br />
            <img alt="" src={blue} />
          </ReactBootStrap.Nav.Link>
          <ReactBootStrap.Nav.Link href="/testimonials" className="nav-link">
            <Translator path="Testimonials" />
            <br />
            <img alt="" src={blue} />
          </ReactBootStrap.Nav.Link>
        </ReactBootStrap.Nav>

        <ReactBootStrap.Nav>
          <I18n />
        </ReactBootStrap.Nav>
      </ReactBootStrap.Navbar.Collapse>
    </ReactBootStrap.Navbar>
  );
};

export default Nav;
