import React, { useEffect } from "react";
import {
  FaTwitter,
  FaFacebook,
  FaSpotify,
  FaYoutube,
  FaEnvelopeOpen,
  FaPhoneAlt,
} from "react-icons/fa";
import Translator from "./i18n/Translator";
import TitleBar from "./TitleBar";

import { Row, Col } from "react-bootstrap";
import kinga from "../assets/imgs/kinga2.png";

const Contact = () => {

useEffect(() => {
window.scrollTo(0,0)
},[])

  return (
    <>
      <div className="content">
        <Row>
          <Col md={6}>
            {" "}
            <div className="contact-form">
              <h4>
                <Translator path="Contact" />
              </h4>
              <p>
                <Translator path="ContactText" />
              </p>
              <div className="contact-options">
                <a href="tel:+44 7906 246 931">
                  <h5>
                    <FaPhoneAlt className="icon" />
                    +44 (0)7906 246 931
                  </h5>
                </a>
                {/* <a href="mailto:gszkinga@hotmail.com">
                  <p>
                    <FaEnvelopeOpen className="icon" />
                    gszkinga@hotmail.com
                  </p>
                </a> */}
              </div>
              <form
                className="form-group"
                action="https://formspree.io/f/xbjpzydl"
                method="POST"
              >
                <label>
                  {" "}
                  <Translator path="ContactFormName" />
                </label>

                <input
                  required
                  className="form-control"
                  type="text"
                  name="name"
                />

                <label>
                  {" "}
                  <Translator path="ContactFormEmail" />
                </label>

                <input
                  required
                  className="form-control"
                  type="email"
                  name="_replyto"
                />
                <label>
                  {" "}
                  <Translator path="ContactFormMessage" />
                  <br />
                </label>

                <textarea
                  required
                  className="form-control"
                  name="message"
                ></textarea>
                <button
                  style={{ marginTop: "5px" }}
                  className="btn btn-light"
                  type="submit"
                >
                  <Translator path="ContactFormButton" />
                </button>
              </form>
            </div>
          </Col>
          <Col md={6}>
            <div className="contact-photo">
              <img style={{ width: "100%" }} src={kinga} />
            </div>
          </Col>
        </Row>

        {/* 
        <div className="contact-social-icons">
          <a
            href="https://www.facebook.com/TabeaDebusRecorder/"
            target="_blank"
          >
            <FaFacebook size={35} />
          </a>

          <a href="https://twitter.com/TabeaDebus" target="_blank">
            <FaTwitter size={35} />
          </a>

          <a
            href="https://www.youtube.com/channel/UCIwHWkZF447ssRHDaTpWJpg"
            target="_blank"
          >
            <FaYoutube size={35} />
          </a>

          <a
            href="https://open.spotify.com/artist/1MbLfjWZWdE2dt3yOJnU4e?si=Pz6mmUhSTG2Nwwu8G5Xlrg"
            target="_blank"
          >
            <FaSpotify size={35} />
          </a>
        </div> */}
      </div>
    </>
  );
};

export default Contact;
